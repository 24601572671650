<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    persistent
    transition="scroll-x-transition"
    scrollable
    :fullscreen="fullscreen"
  >
    <!-- is there a slot -->
    <v-card
      v-if="$slots.message"
      :class="{ 'overflow-y-hidden': !noOverFlow, 'py-3': !noPy }"
      :disabled="loading"
      :height="height || 'auto'"
    >
      <v-card-text :class="{ 'pt-5': fullscreen, 'px-0': noPy }">
        <slot name="message"></slot>
      </v-card-text>
      <v-btn
        @click="(dialog = false), $emit('close')"
        style="z-index: 99"
        :disabled="loading"
        icon
        absolute
        top
        right
        v-if="!hideClose"
        ><v-icon>close</v-icon>
      </v-btn>
    </v-card>
    <!-- if there is no slot, because this components has the non slot version and slot version -->
    <v-card v-else :disabled="loading">
      <v-card-title class="font-weight-bold">
        {{ title || "Are you sure?" }}
      </v-card-title>
      <v-card-text
        :style="isMobile ? 'font-size: 15px' : ''"
        class="message mx-auto"
      >
        {{ message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- cancel -->
        <v-btn
          :disabled="loading"
          class="main"
          :class="{ primary: sensitiveAction }"
          text
          @click="Cancel"
          id="cancel"
        >
          {{ cancelText || "No" }}
        </v-btn>
        <!-- confirm -->
        <v-btn
          :loading="loading"
          class="main"
          :class="{ primary: !sensitiveAction }"
          @click="Confirm"
          id="confirm"
        >
          {{ confirmText || "Yes" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    cancelText: String,
    confirmText: String,
    message: String,
    title: String,
    onConfirm: Function,
    onCancel: Function,
    noOverFlow: Boolean,
    sensitiveAction: Boolean,
    fullscreen: Boolean,
    hideClose: Boolean,
    noPy: Boolean,
  },
  data() {
    return {
      dialog: null,
    };
  },

  watch: {
    dialog(v) {
      this.$emit("input", v);
    },
    value(v) {
      this.dialog = v;
    },
  },
  methods: {
    Cancel() {
      this.$emit("onCancel");
      this.dialog = false;
    },
    Confirm() {
      this.$emit("onConfirm");
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.dialog = this.value;
    });
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.overflow-visible {
  overflow: visible !important;
}
</style>
